import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Card, FormControlLabel, Grid, Stack, Switch, Typography, } from '@mui/material';
// components
import FormProvider, { RHFAutocomplete, RHFTextField, } from '../../../components/hook-form';
import Label from '../../../components/label';
// @mui
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useLocales } from '../../../locales';
import { createPatient, getOnePatient, getPatients, } from '../../../redux/slices/patient/patientThunk';
import { resetStep } from '../../../redux/slices/prothese/protheseSlice';
import { dispatch } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useDebounce from '../../../hooks/useDebounce';
import { compareObjectsByKey } from '../../../utils/compareObjectsByKey';
import CustomDivider from '../../../components/custom-divider';
export default function PatientNewForm({ isEdit = false, currentUser, setOpenConfirm, setCurrentId, handleNext, }) {
    const [selected, setSelected] = useState(false);
    const date = new Date();
    const { step, id, treatmentId } = useParams();
    const navigate = useNavigate();
    const { translate } = useLocales();
    const { data, onData } = useSelector((state) => state.patient);
    const NewUserSchema = Yup.object().shape({
        name: Yup.string().required('Le nom est requis'),
    });
    const { enqueueSnackbar } = useSnackbar();
    const defaultValues = useMemo(() => ({
        name: currentUser?.name || '',
        phone: currentUser?.phone || '',
        birthday: '',
        description: '',
        file: null,
        gender: '',
        isSelectedUser: null,
    }), []);
    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });
    const { watch, control, setValue, handleSubmit, formState: { isSubmitting, dirtyFields }, } = methods;
    const values = watch();
    useEffect(() => {
        dispatch(getPatients({ limit: 25, page: 1 }));
    }, []);
    useEffect(() => {
        id && dispatch(getOnePatient({ id }));
    }, [id]);
    const keysToCompare = ['name', 'file', 'birthday', 'gender', 'description', 'phone'];
    const { isModified, notEqualKeys } = compareObjectsByKey({ ...onData, file: onData?.avatar === '' ? null : onData?.avatar }, values, keysToCompare);
    useEffect(() => {
        if (id && onData) {
            const { name, gender, phone, birthday, description, avatar } = onData;
            const valuesToUpdate = {
                idSelectedUser: id,
                isSelectedUser: true,
                name: name ?? '',
                gender: gender ?? '',
                phone: phone ?? '',
                birthday: birthday ?? '',
                description: description ?? '',
                file: avatar ?? '',
            };
            Object.entries(valuesToUpdate).forEach(([key, value]) => {
                setValue(key, value);
            });
        }
    }, [id, onData]);
    useDebounce(() => {
        dispatch(getPatients({
            limit: 25,
            search: values?.name,
            page: 1,
        }));
    }, 400, [values?.name]);
    const formatedPatientData = data.map((patient) => {
        return {
            label: patient?.name,
            value: patient.id,
        };
    });
    const onSubmit = async (dataPatient) => {
        const dirtyData = {};
        const { file, idSelectedUser, isSelectedUser, ...data } = values;
        notEqualKeys.forEach((key) => {
            dirtyData[key] = data[key];
        });
        try {
            if (id || values?.isSelectedUser) {
                dispatch(resetStep());
                navigate(PATH_DASHBOARD.patientForm
                    .replace(':step', '2')
                    .replace(':id?', idSelectedUser)
                    .replace(':treatmentId?', treatmentId || '') + `?lastStepCompleted=${2}`);
                handleNext();
            }
            else {
                if (!data?.phone)
                    delete data?.phone;
                if (!data?.gender)
                    delete data?.gender;
                if (!data?.birthday)
                    delete data?.birthday;
                const result = (await dispatch(createPatient({ params: !values.file ? { ...data } : { ...data, file: values.file } })));
                if (result?.payload?.statusCode === 200) {
                    setOpenConfirm(true);
                    setCurrentId(result?.payload?.data?.id);
                }
                else {
                    enqueueSnackbar(result?.payload?.message, { variant: 'error' });
                }
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    const styles = {
        color: 'text.secondary',
        marginBottom: '1rem',
    };
    const card = {
        padding: '2rem',
        mx: '10%',
        my: '1rem',
    };
    return (_jsxs(FormProvider, { methods: methods, onSubmit: handleSubmit(onSubmit), children: [_jsx(Typography, { variant: "body1", textAlign: "center", mb: 4, mt: 2, color: "#6D758F", children: "Entrez le nom du nouveau patient ou choisissez parmi les patients existants." }), _jsxs(Card, { sx: card, children: [_jsx(CustomDivider, { title: `${translate('patient_info.title')}`, dividerColor: "#CDE8FF" }), _jsxs(Grid, { container: true, spacing: 3, justifyContent: 'center', alignItems: 'center', children: [_jsxs(Grid, { item: true, xs: 12, md: 12, children: [_jsx(RHFAutocomplete, { name: "name", label: `${translate('patient_info.name')}`, options: formatedPatientData, freeSolo: true, onInputChange: (event, newValue) => {
                                            setValue('name', newValue);
                                            if (selected) {
                                                const resetValues = {
                                                    gender: '',
                                                    phone: '',
                                                    birthday: '',
                                                    isSelectedUser: false,
                                                    file: null,
                                                    description: '',
                                                };
                                                Object.entries(resetValues).forEach(([key, value]) => setValue(key, value));
                                                setSelected(false);
                                            }
                                        }, onChange: (event, newValue) => {
                                            if (newValue) {
                                                setSelected(true);
                                                setValue('idSelectedUser', newValue?.value || '');
                                                setValue('isSelectedUser', newValue?.value ? true : false);
                                                setValue('name', newValue?.label || newValue || '');
                                                const user = data?.find((el) => el?.id == newValue?.value);
                                                const defaultValues = {
                                                    gender: user?.gender,
                                                    phone: user?.phone,
                                                    birthday: user?.birthday,
                                                    file: user?.avatar,
                                                    description: user?.description,
                                                };
                                                Object.entries(defaultValues).forEach(([key, value]) => {
                                                    setValue(key, value);
                                                });
                                            }
                                        }, sx: { mt: 2 } }), _jsx(RHFTextField, { disabled: values?.isSelectedUser, name: "phone", type: "number", label: `${translate('patient_info.phone')}`, sx: { my: 2 } })] }), _jsxs(Grid, { item: true, xs: 12, md: 4, children: [isEdit && (_jsx(Label, { color: values.status === 'active' ? 'success' : 'error', sx: { textTransform: 'uppercase', position: 'absolute', top: 24, right: 24 }, children: values.status })), isEdit && (_jsx(FormControlLabel, { labelPlacement: "start", control: _jsx(Controller, { name: "status", control: control, render: ({ field }) => (_jsx(Switch, { ...field, checked: field.value !== 'active', onChange: (event) => field.onChange(event.target.checked ? 'banned' : 'active') })) }), label: _jsxs(_Fragment, { children: [_jsx(Typography, { variant: "subtitle2", sx: { mb: 0.5 }, children: "Banned" }), _jsx(Typography, { variant: "body2", sx: { color: 'text.secondary' }, children: "Apply disable account" })] }), sx: { mx: 0, mb: 3, width: 1, justifyContent: 'space-between' } }))] }), _jsx(Grid, { item: true, xs: 12, md: 4 })] }), _jsx(Typography, { variant: "subtitle1", sx: styles, children: `${translate('patient_info.addition')}` }), _jsx(RHFTextField, { disabled: values?.isSelectedUser, name: "description", label: "Ajouter  les informations" })] }), _jsx(Stack, { alignItems: "center", sx: { mt: 3 }, children: _jsx(LoadingButton, { type: "submit", variant: "contained", size: "large", loading: isSubmitting, sx: { px: 5, py: 4, fontSize: '18px' }, children: id && isModified
                        ? `${translate('patient_info.save')}`
                        : `${translate('patient_info.create')}` }) })] }));
}
